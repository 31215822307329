// Generated by Framer (ea5112a)

import { addFonts, cx, CycleVariantState, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["qOEBNvl6G"];

const variantClassNames = {qOEBNvl6G: "framer-v-5ws44"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "qOEBNvl6G", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "qOEBNvl6G", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-pEucz", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-5ws44", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"qOEBNvl6G"} ref={ref} style={{...style}} transition={transition}><SVG className={"framer-lhiq2p"} data-framer-name={"AOL"} fill={"rgba(0,0,0,1)"} intrinsicHeight={47} intrinsicWidth={115} layoutDependency={layoutDependency} layoutId={"wIscX4Ptd"} svg={"<svg width=\"115\" height=\"47\" viewBox=\"0 0 115 47\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<g clip-path=\"url(#clip0_6691_99982)\">\n<path d=\"M63.3181 10.1113C52.9895 10.1113 45.1758 18.3234 45.1758 28.4965C45.1758 39.2212 53.3189 46.8817 63.3181 46.8817C73.3173 46.8817 81.4304 39.2212 81.4304 28.4965C81.4304 18.3234 73.6466 10.1113 63.3181 10.1113ZM63.3181 19.8248C67.7788 19.7942 71.4312 23.6857 71.4312 28.4965C71.4312 33.2767 67.7788 37.1682 63.3181 37.1682C58.8573 37.1682 55.2049 33.2767 55.2049 28.4965C55.2049 23.6857 58.8573 19.8248 63.3181 19.8248Z\" fill=\"#212427\"/>\n<path d=\"M115 40.4465C115 44.001 112.185 46.8814 108.713 46.8814C105.24 46.8814 102.426 44.001 102.426 40.4465C102.426 36.8921 105.24 34.0117 108.713 34.0117C112.185 34.0117 115 36.8921 115 40.4465Z\" fill=\"#212427\"/>\n<path d=\"M96.6998 45.6566H86.8203V0H96.6998V45.6566Z\" fill=\"#212427\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 45.6566L17.9627 0H27.2434L44.9067 45.6566H32.3328L30.2372 39.2217H14.6695L12.5739 45.6566H0ZM27.2434 29.7227H17.9627L22.603 14.4017L27.2434 29.7227Z\" fill=\"#212427\"/>\n</g>\n<defs>\n<clipPath id=\"clip0_6691_99982\">\n<rect width=\"115\" height=\"47\" fill=\"white\"/>\n</clipPath>\n</defs>\n</svg>\n"} transition={transition} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-pEucz [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pEucz .framer-t0c3gj { display: block; }", ".framer-pEucz .framer-5ws44 { height: 47px; overflow: hidden; position: relative; width: 115px; }", ".framer-pEucz .framer-lhiq2p { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 47
 * @framerIntrinsicWidth 115
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramernVSRYQkj1: React.ComponentType<Props> = withCSS(Component, css, "framer-pEucz") as typeof Component;
export default FramernVSRYQkj1;

FramernVSRYQkj1.displayName = "AOL Logo";

FramernVSRYQkj1.defaultProps = {height: 47, width: 115};

addFonts(FramernVSRYQkj1, [])